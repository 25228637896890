<template>
  <div class="d-inline-block">
    <div :class="getStatusBadge(compaignStatus)">
      <div class="badge-text-sm">
        {{ getStatus(compaignStatus) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    compaignStatus: {
      type: String,
      default : this
    }
  },
  methods: {
    getStatusBadge (status) {
      switch (status) {
        case 'pending': return 'badge-score-pending-confirmation';
        case 'draft': return 'badge-score-draft-confirmation';
        case 'delivered': return 'badge-available-success-confirmation';
        case 'sending': return 'badge-available-sending-confirmation';
        case 'canceled': return 'badge-available-error-confirmation';
        case 'failed': return 'badge-available-failed-confirmation';
      }
    },
    getStatus (status) {
      return this.$t('views.marketing.content.bottom.compaignStatus.' + status);
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/campaign/email/_email-status.scss";
</style>
